<template>
  <div v-for="(item,index) in mydata" v-bind:key="index" class="hu_qualifications">

    <div class="hu_qualifications_tltle">{{item.cname}}</div>
    <van-empty description="暂无" v-if="item.list.length==0" />

    <div class="hu_qualifications_content" v-else>
      <img v-for="(item2,index) in item.list" :key="index" :src="item2.recpic" alt="" srcset=""
        @click="openImage(item.list, index)" />
    </div>

    <!-- {{item}} -->
  </div>

</template>
<script>

import { ImagePreview } from "vant";
import {
  com_info_list_xkz_all
} from "@/api/index";
export default {
  props: {
    myvalue: {
      default: []
    },
  },
  data() {
    return {
      mydata: [],
      sdata: { page: 1 },
    };
  },
  created() {
  },
  mounted() {
    this.load_api();
  },
  methods: {
    load_api() { this.get_json() },
    get_json() {
      com_info_list_xkz_all(this.myvalue).then((res) => {
        console.log('com_info_list_xkz_all', res)
        this.mydata = res.data.mydata;
      });
    },
    openImage(data, index) {
      let new_pic = [];
      for (let d in data) {
        new_pic.push(data[d].recpic);
      }

      ImagePreview({
        images: new_pic,
        startPosition: index,
        closeable: true,
      });
    },
  },
};
</script>
<style scoped lang="less">
.hu_qualifications {
  height: auto;
  margin-top: 0.5rem;
  padding: 0.7rem;
  background: white;
  .hu_qualifications_tltle {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-left: 4px solid rgb(255, 112, 17);
    font-size: 15px;
    font-weight: 600;
    text-indent: 1em;
    line-height: 1.5rem;
  }
  .hu_qualifications_content {
    padding: 1rem 0;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: -webkit-center;
    display: block;
    img {
      width: 90%;
      height: auto;
      border-radius: 5px;
      border: 1px solid rgb(235, 235, 235);
      margin-top: 0.2rem;
    }
  }
}
</style>