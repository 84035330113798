<template>
    <div class="tzc_demo">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      >
      </video-player>
    </div>
</template>

<script>
 
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import {
  com_list,
  com_cam_list,
  com_cam_list_url,
  comcity_cam,
} from "@/api/index";
export default {
  name: "vidoList",
  props: ['IDdata'],
  watch: {
    IDdata(a){
      this.Video_u(a);
      console.log('cs',a)
    }
  },
  data() {
    return {
      playerOptions:{
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        html5: { hls: { withCredentials: false } },
        flash: { hls: { withCredentials: false } },
        sources: [
          {
            type: "application/x-mpegURL", // 类型
            src: '', // url地址
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
      video_s:{},
    }
  },
  mounted() {
    this.Video_u();
  },
  methods: {
    Video_u(){
      com_cam_list_url({guid:this.IDdata}).then((res) => {
        this.video_s = res.data.mydata;
        this.playerOptions.sources[0].src=res.data.mydata.recurl;
        console.log('ls',this.playerOptions)
      }).catch((err) => {
        console.log(err);
      });
    }
  },
};
</script>
<style scoped lang="less">
/deep/.vjs-modal-dialog .vjs-modal-dialog-content{
  z-index: 0;
}
.box-viods {
  margin-bottom: 3.4rem;
//   .tzc_demo_bt{
//       width: 100%;
//       height: 2rem;
//       text-align: center;
//       line-height: 2rem;
//   }
}
.tzc_box_vlist {
  margin-top: 3rem;
  margin-bottom: 4rem;
}
.viods-list {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.5rem;
  padding-top: 0.5rem;
}
.list-title {
  width: 100%;
  height: 2.3rem;
  background: #007acc;
  color: white;
  line-height: 2.4rem;
  font-weight: bold;
  font-size: 1rem;
  box-sizing: border-box;
  padding: 0 0.8rem;
  border-radius: 6px;
}
.list-video {
  width: 100%;
  height: 10rem;
  overflow: hidden;
  background: rgb(233, 246, 255);
  position: relative;
  .tzc_icon_video {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 4rem;
    color: #007acc;
  }
  // img {
  //   width: 100%;
  //   height: auto;
  // }
}
/deep/ .van-nav-bar .van-icon {
  color: #333333;
}
/deep/ .van-nav-bar__title {
  font-weight: bold;
  font-size: 1rem;
}
</style>