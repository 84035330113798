<template>
  <div class="hu_video_details">
    <van-nav-bar :title="Details.retitle" left-text="返回" left-arrow fixed placeholder @click-left="$router.go(-1)" @click-right="onClickRight">
      <template #right>
        <van-icon name="star-o" size="18" v-show='flag1' />
        <van-icon name="star" size="18" color="rgb(252, 177, 38)" v-show='flag2' />
      </template>
    </van-nav-bar>
    <!-- {{Details}} -->
    <!-- 详情内容开始 -->
    <div class="hu_box_Details">
      <!-- 有无监控视频切换部分 -->
      <div class="hu_video_box">
        <div class="hu_video" v-if="Switch==0">
          <img :src="Details.repic" alt="" srcset="">
          <!-- <img :src="'http://192.168.0.10:9901'+Details.repic" alt="" srcset=""> -->
        </div>
        <Video :IDdata='IDdata' v-else />
      </div>
      <!-- 详情 -->
      <div class="hu_video_details">
        <div class="hu_details_first">
          <div class="hu_details_first_son1">
            <van-rate v-model="Details.reisgood" :size="20" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
            <!-- <div class="next_hu_list">0条</div> -->
          </div>
          <div class="hu_details_first_son2" v-if="Switch==1">
            <router-link :to="'/comment/'+Details.pkguid">
              +评论商家
            </router-link>
          </div>
        </div>
        <div class="hu_details_second">
          <div class="hu_details_second_son1">
            <div>
              <van-icon name="eye-o" />
              <span>{{Details.rehits}}</span>
            </div>
            <!-- <div>
                        <van-icon name="good-job-o" />
                        <span>12</span>
                    </div> -->
          </div>
          <div class="hu_details_second_son2">{{Details.ecname}}</div>
        </div>
        <div class="list" v-if="Switch==1">
          <van-tabs v-model:active="active" type="card" @click-tab="onClickTab" swipe-threshold='1' color='#b5cde2' title-inactive-color='black'>
            <van-tab v-for="(item,index) in vlist" :key="index" :name="item.pkguid">
              <template #title>
                <img src="@/assets/images/ximg/hu_camera.png" alt="" srcset="">
                <div>{{item.rectitle}}</div>
              </template>
            </van-tab>
          </van-tabs>
        </div>
        <div class="hu_address">
          <div class="hu_address_details">
            <van-icon name="location-o" />
            <span>{{Details.readdress}}</span>
          </div>
          <!-- <div class="hu_address_phone">
                <van-icon name="phone-o" size="1.2rem" color="rgb(252, 177, 38)"/>
              </div> -->
        </div>
        <!-- 拨打12315 -->
        <a href="tel:12315">
          <van-grid direction="horizontal" :column-num="1" :gutter="2">
            <van-grid-item icon="phone-circle-o" text="拨打12315" />
          </van-grid>
        </a>
      </div>
    </div>
    <!-- 资质证件相关 -->
    <cpd_scxkz_list_type :myvalue="myguid" />

    <!-- <div class="hu_qualifications">
      <div class="hu_qualifications_tltle">许可证</div>
      <van-empty description="暂无" v-if="XKZdata.length==0" />
      <div class="hu_qualifications_content" v-else>
        <img v-for="(item,index) in XKZdata" :key="index" :src="item.recpic" alt="" srcset="">
      </div>
    </div>
    <div class="hu_qualifications">
      <div class="hu_qualifications_tltle">健康证</div>
      <van-empty description="暂无" v-if="JKZdata.length==0" />
      <div class="hu_qualifications_content" v-else>
        <img v-for="(item,index) in JKZdata" :key="index" :src="item.recpic" alt="" srcset="">
      </div>
    </div> -->
    <!-- 评论组件相关 -->
    <div class="hu_qualifications2" v-if="Switch==1">
      <div class="hu_qualifications_tltle2">评论</div>
      <Comment_list />
    </div>
    <div class="hu_button_pl" @click="Submits()" v-if="Switch==1">
      <van-button block color="#fd8412" native-type="submit">
        评论商家
      </van-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Toast } from 'vant'
import Video from './video.vue';
import Comment_list from '@/components/comment/comment_list.vue'
import cpd_scxkz_list_type from '@/components/cpd_scxkz_list_type.vue'
import {
  com_list,
  com_cam_list,
  com_cam_list_url,
  comcity_cam,
  com_info,
  com_info_list_xkz,
  com_info_list_jkz,
  com_info_me,
  com_info_me_sl,
  getccconfig
} from "@/api/index";
export default {
  setup() {
    const value2 = ref('3');//星级评分
    let $route = useRouter();
    let eqery = {
      pagesize: 100,
      guid: $route.currentRoute.value.params.id,
    };
    let myguid = $route.currentRoute.value.params.id;
    let vlist = ref([]);//视频列表
    let Details = ref({});//详情
    let Pkguid = ref('');
    //播放器判断
    let Ifcondition = ref(false);
    let Ifcondition2 = ref(true);
    //播放器id
    let IDdata = ref('');
    //证件列表
    let XKZdata = ref([]);
    let JKZdata = ref([]);
    //企业收藏
    let flag1 = ref(true);
    let flag2 = ref(false);
    //详情部分内容开关
    let Switch = ref({});
    const video_list = () => {
      com_cam_list(eqery)
        .then((res) => {
          vlist.value = res.data.mydata.data;
          IDdata.value = res.data.mydata.data[0].pkguid;
          console.log('id', IDdata.value)
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const Business_details = () => {
      com_info(eqery).then((res) => {
        Details.value = res.data.mydata;
        Pkguid.value = res.data.mydata.pkguid;
        console.log("xq", Details.value);
      }).catch((err) => {
        console.log(err)
      });
    }
    // 选项卡点击方法
    const onClickTab = ({ name }) => {
      IDdata.value = name;
      console.log('TAB', IDdata.value)
    };
    //评论跳转
    const Submits = () => {
      Business_details();
      console.log('ly', Pkguid.value);
      $route.push("/comment/" + Pkguid.value)
    };
    //证件相关方法
    const xkz = (data) => {
      console.log('参数', data)
      com_info_list_xkz({ cname: data.retitle }).then((res) => {
        XKZdata.value = res.data.mydata.data;
        console.log('zj', XKZdata.value)
      }).catch((err) => {
        console.log(err);
      });
    };
    const jkz = (data) => {
      com_info_list_jkz({ cname: data.retitle }).then((res) => {
        JKZdata.value = res.data.mydata.data;
      }).catch((err) => {
        console.log(err);
      });
    };
    //企业是否收录查询
    const Collection_query = () => {
      com_info_me(eqery).then((res) => {
        // console.log('sl',res.data)
        if (res.data.mydata == 'ok') {
          flag1.value = false;
          flag2.value = true;
        } else {
          flag1.value = true;
          flag2.value = false;
        }
      }).catch((err) => {
        console.log(err);
      });
    };
    //企业点击收藏相关方法
    const onClickRight = () => {
      com_info_me(eqery).then((res) => {
        if (res.data.mydata == '') {
          com_info_me_sl({ ctype: 1, guid: $route.currentRoute.value.params.id }).then((res) => {
            if (res.data.statusCode == '200') {
              flag1.value = false;
              flag2.value = true;
              Toast.success(res.data.message);
            }
          }).catch((err) => {
            console.log(err);
          });
        } else {
          com_info_me_sl({ ctype: 0, guid: $route.currentRoute.value.params.id }).then((res) => {
            if (res.data.statusCode == '200') {
              flag1.value = true;
              flag2.value = false;
              Toast.fail(res.data.message);
            }
          }).catch((err) => {
            console.log(err);
          });
        }
      }).catch((err) => {
        console.log(err);
      });
    };
    //详情内容开关方法
    const Sflag = () => {
      let query = {
        info: 'flag'
      };
      getccconfig(query).then((res) => {
        let d = res.data.mydata;
        Switch.value = eval('(' + d + ')').flag;
        console.log(Switch.value)
      }).catch((err) => {
        console.log(err)
      });
    }
    onMounted(() => {
      // xkz();
      // jkz();
      video_list();
      Business_details();
      Collection_query();
      Sflag();
    });
    return {
      vlist,
      video_list,
      value2,
      //详情数据
      Details,
      Business_details,
      //滑动视频列表点击事件
      onClickTab,
      Ifcondition,
      Ifcondition2,
      IDdata,
      Submits,
      XKZdata,
      JKZdata,
      flag1,
      flag2,
      onClickRight,
      Switch,
      myguid
    };
  },
  components: {
    Video,
    Comment_list,
    cpd_scxkz_list_type
  },
};
</script>
<style scoped lang="less">
//头部样式
/deep/ .van-nav-bar__placeholder {
  z-index: 99999;
}
/deep/.van-nav-bar {
  z-index: 99999;
}
/deep/ .van-nav-bar__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .van-icon-arrow-left {
  color: #323233;
}
/deep/ .van-nav-bar__text {
  color: #323233;
}
/deep/ .van-icon-star-o {
  color: #323233;
}
.hu_box_Details {
  height: auto;
  padding: 0.7rem;
  margin-top: 0.3rem;
  background: rgb(255, 255, 255);
  .hu_video_box {
    width: 100%;
    .hu_video {
      width: 100%;
      height: 12rem;
      border-radius: 5px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .hu_video_details {
    width: 100%;
    height: auto;
    padding-top: 1rem;
    .hu_details_first {
      width: 100%;
      height: auto;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 14px;
      font-weight: 600;
      color: rgb(19, 19, 19);
      .hu_details_first_son1 {
        width: auto;
        padding-top: 0.5rem;
        /deep/ .van-rate--readonly {
          float: left;
        }
        .next_hu_list {
          float: left;
          margin-left: 0.5rem;
        }
      }
      .hu_details_first_son2 {
        width: auto;
        padding-top: 0.5rem;
        color: rgb(252, 177, 38);
        a {
          color: rgb(252, 177, 38);
        }
      }
    }
    .hu_details_second {
      width: 100%;
      height: auto;
      margin-top: 0.2rem;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 14px;
      color: rgb(97, 97, 97);
      .hu_details_second_son1 {
        width: auto;
        padding-top: 0.5rem;
        div {
          float: left;
          // margin-left: 0.5rem;
        }
      }
      .hu_details_second_son2 {
        div {
          color: rgb(97, 97, 97);
          font-size: 12px;
        }
      }
    }
    .list {
      width: 100%;
    }
    .hu_address {
      width: 100%;
      height: auto;
      margin-top: 0.2rem;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 15px;
      color: rgb(34, 34, 34);
      .hu_address_details {
        max-width: 100%;
        padding-top: 0.5rem;
        /deep/.van-icon-location-o {
          float: left;
          line-height: 2rem;
        }
        span {
          display: inline-block;
          float: left;
          max-width: 100%;
          // overflow: hidden;
          // text-overflow:ellipsis;
          // white-space: nowrap;
          line-height: 2rem;
        }
      }
      .hu_address_phone {
        padding-top: 0.5rem;
        line-height: 2rem;
        div {
          font-size: 14px;
          // color: rgb(255, 232, 24);
        }
      }
    }
  }
}
/deep/ .van-tabs--card {
  height: 9rem;
}
/deep/ .van-tabs__wrap {
  height: 9rem;
}
/deep/ .van-tabs__nav--card {
  margin: 0 0;
  margin-top: 0.5rem;
  height: 8rem;
}
/deep/ .van-tab {
  width: 9rem;
}
/deep/ .van-tab__text {
  position: relative;
  img {
    width: 5rem;
    height: auto;
  }
  div {
    width: 5rem;
    text-align: center;
    font-size: 16px;
    color: black !important;
  }
}
// 拨打12315
/deep/ .van-grid {
  margin-bottom: 0.5rem;
}
/deep/ .van-icon-phone-circle-o {
  color: rgb(255, 172, 17);
  font-size: 30px;
}
/deep/ .van-grid-item__text {
  font-weight: 600;
  font-size: 15px;
}

//资质，评论
.hu_qualifications {
  height: auto;
  margin-top: 0.5rem;
  padding: 0.7rem;
  background: white;
  .hu_qualifications_tltle {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-left: 4px solid rgb(255, 112, 17);
    font-size: 15px;
    font-weight: 600;
    text-indent: 1em;
    line-height: 1.5rem;
  }
  .hu_qualifications_content {
    padding: 1rem 0;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
      border: 1px solid rgb(235, 235, 235);
      margin-top: 0.2rem;
    }
  }
}
.hu_qualifications2 {
  height: auto;
  margin-top: 0.5rem;
  padding: 0.7rem;
  background: white;
  margin-bottom: 3rem;
  .hu_qualifications_tltle2 {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-left: 4px solid rgb(255, 112, 17);
    font-size: 15px;
    font-weight: 600;
    text-indent: 1em;
    line-height: 1.5rem;
  }
}
.hu_button_pl {
  width: 100%;
  z-index: 999999;
  position: fixed;
  left: 0;
  bottom: 0;
}
</style>